<template>
    <Input v-model="valueSync" type="number" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import Input from "./Input";

const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
});

export default {
    model: {props: "value", event: EventsEnum.VALUE_UPDATE},
    components: {
        Input,
    },
    props: {
        value: null,
    },
    computed: {
        valueSync: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
    },
};
</script>
