<template>
    <textarea v-model="valueSync" class="tw-shadow-sm tw-mt-1 tw-block tw-w-full tw-border-gray-300 tw-outline-none tw-p-2 focus:tw-border-blue-400 tw-border-solid tw-border tw-box-border tw-text-sm tw-text-gray-800 tw-rounded-md" rows="5" />
</template>

<script>
const EmitsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
});

export default {
    model: {
        prop: "value",
        event: EmitsEnum.VALUE_UPDATE,
    },
    props: {
        value: String,
    },
    computed: {
        valueSync: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(EmitsEnum.VALUE_UPDATE, val);
            },
        },
    },
};
</script>
<style lang="scss" scoped>
textarea.invalid-field-border {
    top: 1px;
    border: 1px solid #f87171 !important;
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
    border-bottom: 1px solid inherit !important;
}
</style>
