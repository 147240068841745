<template>
    <div>
        <div class="tw-flex tw-gap-2 tw-mr-2 tw-items-center tw-mb-4" v-for="(item, idx) in listValue" :key="idx">
            <VueMultiselect :value="item.platform" @input="onChange($event, 'platform', idx)" v-bind="$attrs" @focus.native="$emit('focus', $event)" selectLabel="" role="combobox" :placeholder="$attrs.placeholder" deselectLabel="" :class="[{'invalid-field-border': item.error && item.platform == ''}]" />
            <NeoInput :value="item.username" @input="onChange($event, 'username', idx)" v-bind="$attrs" :bg="'#fff'" placeholder="URL" :borderFull="'1px solid #dedede'" role="textbox" :class="[{'invalid-field-border': item.error && item.username == ''}]" />
            <label class="disableLabel tw-text-sm tw-font-medium tw-flex-grow" :class="{'tw-text-blue-700 enableLabel tw-cursor-pointer': platformExists(idx) && getCaseId == 'NEW'}" @click="clearPlatform(idx)">Clear</label>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "@/components/vue-multiselect";
import NeoInput from "@/components/input";
import {mapGetters} from "vuex";

let EventsEnum = {
    VALUE_UPDATE: "value:update",
};
export default {
    components: {
        VueMultiselect,
        NeoInput,
    },
    data() {
        return {
            platform: "",
            username: "",
            listValue: [],
        };
    },
    props: {
        value: {},
        label: {
            type: String,
            default: "",
        },
    },
    computed: {
        ...mapGetters(["getCaseId"]),

        valueSync: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
    },
    methods: {
        onChange(val, type, idx) {
            let obj = {
                platform: this.getValue(idx, "platform") || "",
                username: this.getValue(idx, "username") || "",
            };
            obj[type] = val;
            this.listValue[idx] = obj;
            if (this.listValue[idx].platform == null) this.listValue[idx].platform = "";
            this.valueSync = [...this.listValue];
        },
        getValue(index, type) {
            return this.valueSync?.[index]?.[type];
        },
        platformExists(idx) {
            if (this.listValue?.[idx]?.platform || this.listValue?.[idx]?.username) return true;
            else return false;
        },
        clearPlatform(idx) {
            let obj = {
                platform: "",
                username: "",
            };
            this.listValue[idx] = obj;
            this.valueSync = [...this.listValue];
        },
    },
    mounted() {
        for (let i = 0; i < this.$attrs.multi_counter; i++) {
            this.listValue.push(this.valueSync?.[i] || {platform: "", username: ""});
        }
    },
};
</script>

<style lang="scss" scoped>
[role="combobox"] {
    width: 70%;
    position: relative;
    &.invalid-field-border {
        top: 1px;
        border: 1px solid #f87171 !important;
        border-radius: 0.315rem !important;
        border-bottom-right-radius: 0.315rem !important;
    }
}
[role="textbox"] {
    width: 99%;
    font-size: 16px !important;
    border-bottom: 1px solid #dedede;
    &.invalid-field-border {
        border: 1px solid;
        top: 1px;
        border: 1px solid #f87171 !important;
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
}
.invalid-field-border {
    top: 1px;
    border-bottom: 2px solid rgba(248, 113, 113, 1) !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.disableLabel {
    pointer-events: none;
    opacity: 0.5;
}
.enableLabel {
    pointer-events: auto !important;
    opacity: 1 !important;
}
::v-deep {
    .multiselect__tags {
        min-height: unset;
    }
    .multiselect__option {
        min-height: unset;
    }
}
</style>
