<template>
    <div class="multi-components">
        <div v-for="(model, i) in valueSync" :key="i" class="tw-relative tw-items-start tw-w-full tw-h-full" :class="i === 0 ? 'tw-flex-col' : ''">
            <slot :index="i" :models="valueSync" />
            <button v-if="i !== 0 && !readOnly" @click="removeModel(i)" type="button" class="tw-inline-flex tw-items-center tw-justify-center tw-text-red-500 tw-border-none tw-bg-gray-100 tw-uppercase tw-cursor-pointer tw-w-8 tw-absolute tw-top-0 tw-right-4" :class="model.attrs.placeholder === 'company' ? 'tw-h-8 tw-my-4 tw-p-2 tw-ml-4' : 'tw-my-0 tw-p-1 tw-ml-4'" title="Remove">
                <font-awesome-icon icon="trash" class="tw-text-sm" />
            </button>
        </div>
        <!-- <br /> -->
        <button v-if="!$slots['add-button'] && !readOnly" type="button" v-text="`+ add ${schema.attrs.label}`" @click="incrementModels()" v-cloak class="tw-bg-transparent tw-my-3 tw-cursor-pointer tw-capitalize hover:tw-bg-blue-500 tw-text-blue-600 tw-rounded-lg tw-font-medium hover:tw-text-white tw-py-2.5 tw-px-4 tw-border tw-border-blue-500 hover:tw-border-transparent tw-shadow-sm"></button>
        <slot name="add-button" :increment-models="incrementModels" />
    </div>
</template>

<script>
const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
});

export default {
    model: {prop: "value", event: EventsEnum.VALUE_UPDATE},
    props: {
        value: Array,
        readOnly: {
            type: Boolean,
            default: false,
        },
        countryCodes: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        schema: null,
    }),
    computed: {
        valueSync: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
        isModelsLengthLess2() {
            return this.valueSync.length < 2;
        },
    },
    methods: {
        incrementModels() {
            this.value.push(this.getSchema());
        },
        removeModel(index) {
            this.value.splice(index, 1);
        },
        getSchema() {
            const schema = {...this.value[0]};
            if (schema.type === "block")
                schema.attrs = {
                    ...schema.attrs,
                    fields: schema.attrs.fields.map((fld) => ({
                        ...fld,
                        value: undefined,
                    })),
                    value: schema.attrs.fields.map((fld) => ({
                        ...fld,
                        value: undefined,
                    })),
                };
            else schema.attrs = {...schema.attrs, value: undefined};
            return schema;
        },
        initSchema() {
            this.schema = this.getSchema();
        },
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
    },
    created() {
        this.initSchema();
    },
};
</script>

<style lang="scss" scoped>
.bg-brand-color {
    background-color: var(--brand-color);
}

.text-brand-color {
    color: var(--brand-color);
}
.button {
}
</style>
