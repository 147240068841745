<template>
    <div class="dynamic-form-element" v-bind="wrapperAttrs" :class="label === 'Country Code' ? 'tw-w-60 tw-bg-gray-100 tw-mr-5' : 'tw-w-full'">
        <label v-if="label && componentsMap.get(component) !== 'Checkbox' && component !== 'block'" class="tw-font tw-text-base tw-inline-block tw-mb-1 tw-text-gray-700">
            <span class="tw-capitalize"> {{ label }} </span>
            <span v-if="required" class="tw-text-red-400 tw-ml-1">*</span>
            <span class="tw-text-xs tw-text-gray-400"> {{ $attrs.help_text }} </span>
        </label>
        <br v-if="component !== 'block'" />

        <template name="help_text"></template>
        <component :is="componentsMap.get(component)" :label="label" v-bind="$attrs" v-on="$listeners" v-model="valueSync" :countryCodes="countryCodes" @focus="setElementAsHasFocused()" @change="$emit('handleChanged')" :class="[{'invalid-field-border': isValidationFailureVisible && inValidRuleMessage}]" />
        <!-- @blur="emitVal(valueSync)" -->
        <div class="tw-relative">
            <span v-show="isValidationFailureVisible" class="tw-absolute tw-border-red-400 tw-text-red-400 tw-text-xs" v-text="inValidRuleMessage" />
        </div>
    </div>
</template>

<script>
import {isPrimitive} from "@/utils/functions";
import Input from "./components/Input";
import NumericInput from "./components/NumericInput";
import FilePond from "./components/FilePond";
import Dropdown from "./components/Dropdown";
import RadioInput from "./components/RadioInput";
import DatePicker from "./components/DatePicker";
import Checkbox from "./components/Checkbox";
import Textarea from "./components/Textarea";
import Combobox from "./components/Combobox";
import MultiBlock from "./components/MultiBlock";

/**
 @const {Array.<String, String>[]} componentsMapEntries
 * this is a Map's Schema, needed on creating in data of Vue
 * and props validation
 * please do not set this entity as a Map
 * Map can be chanched in data
 * data set a getters and setters for object
 * currently Vue doesn't support a Map, but Vue can in future
*/
const componentsMapEntries = [
    ["input", "Input"],
    ["file_upload", "FilePond"],
    ["dropdown", "Dropdown"],
    ["radio", "RadioInput"],
    ["date", "DatePicker"],
    ["numeric", "NumericInput"],
    ["checkbox", "Checkbox"],
    ["textarea", "Textarea"],
    ["combo", "Combobox"],
    ["block", "MultiBlock"],
];

const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
});

const isNotEmpty = (val) => {
    if (isPrimitive(val)) throw new TypeError("val cannot be a primitive value");

    for (const key in val) return true;

    return false;
};

export default {
    inheritAttrs: false,
    model: {prop: "value", event: EventsEnum.VALUE_UPDATE},
    components: {
        Input,
        NumericInput,
        FilePond,
        RadioInput,
        Dropdown,
        DatePicker,
        Checkbox,
        Textarea,
        Combobox,
        MultiBlock,
    },
    props: {
        component: {
            type: String,
            required: true,
            validator(component) {
                const componentsMap = new Map(componentsMapEntries);

                if (!componentsMap.has(component)) {
                    return false;
                }

                return true;
            },
        },
        required: Boolean,
        countryCodes: {
            type: Array,
            default: () => [],
        },
        rules: {
            type: Array,
            default: () => [],
        },
        configs: {
            type: Array,
            default: () => [],
        },
        label: String,
        value: null,
        wrapperAttrs: Object,
        disableValidation: Boolean,
    },
    data: () => ({
        componentsMap: new Map(componentsMapEntries),
        hasFocused: false,
    }),
    computed: {
        valueSync: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
        inValidRuleMessage() {
            const rules = this.disableValidation ? [] : this.required ? [...this.rules, this.requiredValidator] : this.rules;

            for (const rule of rules) {
                const ruleResult = rule(this.valueSync);

                if (ruleResult !== true) return ruleResult;
            }

            return null;
        },
        isValidationFailureVisible() {
            if (this.disableValidation) return false;
            return !!this.inValidRuleMessage && this.hasFocused;
        },
    },
    methods: {
        requiredValidator() {
            const requiredFailmessage = "Required";

            return isPrimitive(this.valueSync) ? !!this.valueSync || requiredFailmessage : isNotEmpty(this.valueSync) || requiredFailmessage;
        },
        setElementAsHasFocused() {
            this.hasFocused = true;
        },
        validate() {
            this.setElementAsHasFocused();
            return this.isValidationFailureVisible;
        },
        emitVal(val) {
            this.valueSync = val;
            this.$emit("changed_form");
        },
    },
};
</script>

<style lang="scss" scoped>
.invalid-field-border {
    top: 1px;
    border-bottom: 2px solid rgba(248, 113, 113, 1) !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
::v-deep {
    div input {
        border-bottom: 2px solid inherit !important;
    }
    ::placeholder,
    .multiselect__placeholder {
        text-transform: capitalize !important;
    }
}
</style>
