<template>
    <form @submit.prevent="onSubmit" class="tw-bg-white tw-border tw-border-gray-100 tw-border-solid tw-rounded-lg tw-shadow">
        <div class="tw-px-4 tw-py-3 tw-border-0 tw-border-b tw-border-gray-100 tw-border-solid tw-bg-gray-50 tw-sm:px-6">
            <h1 class="tw-text-gray-700 tw-tracking-wider tw-capitalize tw-text-lg tw-font-medium tw-my-0" v-text="headerText" />
        </div>
        <div class="tw-p-4">
            <template v-for="(model, i) in models">
                <MultiComponents v-if="isMultipleModel(model)" :key="i" v-model="models[i]" :readOnly="readOnly" :countryCodes="countryCodes">
                    <template v-slot="{index, models: multiElementModels}">
                        <div class="tw-text-gray-600 tw-italic tw-pb-2" v-text="multiElementModels[index].attrs.help_text" v-if="multiElementModels[index].attrs && index === 0"></div>
                        <DynamicFormElement v-model="multiElementModels[index].attrs.value" :component="multiElementModels[index].type" v-bind="multiElementModels[index].attrs" :required="multiElementModels[index].mandatory" :configs="configs" :readOnly="readOnly" :countryCodes="countryCodes" class="tw-my-4" ref="form-components" @handleChanged="isChanged = true" />
                    </template>
                </MultiComponents>

                <DynamicFormElement v-else :key="i" v-model="model.attrs.value" :component="model.type" ref="form-components" v-bind="model.attrs" :readOnly="readOnly" :countryCodes="countryCodes" :required="model.mandatory" class="tw-mb-8 tw-mt-4 tw-w-full" @handleChanged="isChanged = true" />
            </template>
        </div>
        <div class="tw-px-4 tw-py-3 tw-bg-gray-50 tw-border-0 tw-border-t tw-border-gray-100 tw-border-solid tw-text-right tw-sm:px-6" v-if="!readOnly">
            <button type="submit" class="tw-py-2 tw-px-5 tw-border tw-bg-brand tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-brand-color hover:tw-bg-brand-hover tw-cursor-pointer" v-text="submitText" />
        </div>
    </form>
</template>

<script>
import DynamicFormElement from "./DynamicFormElement";
import MultiComponents from "./MultiComponents.vue";
import {omit, isPrimitive} from "../utils/functions";
import {cloneDeep} from "lodash";

const isNotEmpty = (val) => {
    if (isPrimitive(val)) throw new TypeError("val cannot be a primitive value");

    for (const key in val) return true;

    return false;
};

const EventsEnum = Object.freeze({
    SUBMIT: "submit",
    VALUE_UPDATE: "value:update",
});

export default {
    components: {
        DynamicFormElement,
        MultiComponents,
    },
    props: {
        configs: {
            required: true,
            type: Array,
        },
        headerText: {type: String, default: "Heading"},
        submitText: {type: String, default: "SUBMIT"},
        values: {
            type: Array,
            default: () => [],
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        countryCodes: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        models: null,
        isChanged: false,
    }),
    methods: {
        getConvertedConfigs() {
            const getConvertedAttrs = (config) => {
                let res = {...config};
                res.attrs = {};

                const notSetAsFormElementPropsMap = new Map([["type"], ["mandatory"], ["validation"], ["attrs"], ["multi"]]);

                for (const key in res) {
                    const isProp = !notSetAsFormElementPropsMap.has(key);

                    if (isProp) {
                        res.attrs[key] = config[key];
                        res = omit(res, key);
                    }
                }
                res.attrs.value = this.values.length ? this.values.find((el) => Object.keys(el).includes(res.attrs?.field_name))?.[res.attrs?.field_name] : undefined;
                if (this.readOnly) res.attrs.disabled = true;
                if (res.type === "checkbox") res.attrs.value = res.attrs.value == "" ? [] : res.attrs.value;
                return res;
            };
            const getConvertedValidator = (config) => {
                const res = {...config};
                const defaultValidationFailText = `Invalid ${res.attrs.label || res.attrs.placeholder}`;
                if (res.attrs.label == "Email Address") {
                    res.attrs.rules = [(val) => new RegExp(config.validation.validation_regex.pattern).test(val) || defaultValidationFailText];
                } else res.attrs.rules = [(val) => new RegExp(config.validation.validation_regex).test(val) || defaultValidationFailText];
                return omit(res, "validation");
            };

            const getConvertedMulti = (config) => {
                const multiOmitedConfig = omit({...config}, "multi");

                return config.multi ? [multiOmitedConfig] : multiOmitedConfig;
            };
            let configs = this.configs?.map((config) => getConvertedMulti(getConvertedValidator(getConvertedAttrs(config))));
            return configs;
        },
        async initComponentsOptionsModels() {
            let models = this.getConvertedConfigs();
            models = models?.map((mod) => {
                let isMulti = Array.isArray(mod);
                if (isMulti) {
                    let newFormField = [];
                    let form = cloneDeep(mod[0]);
                    let field_name = form.attrs?.placeholder?.split(" ").join("_");
                    if (this.values.some((el) => Object.keys(el).includes(form.attrs.field_name))) {
                        this.values.forEach((val) => {
                            let obj = new Object();
                            Object.assign(obj, form);
                            newFormField.push({
                                ...obj,
                                attrs: {
                                    ...obj.attrs,
                                    value: val[field_name],
                                },
                            });
                        });
                        return newFormField;
                    } else if (form.type === "block" && form.attrs.fields) {
                        this.values.forEach((val) => {
                            let obj = new Object();
                            Object.assign(obj, form);
                            newFormField.push({
                                ...obj,
                                attrs: {
                                    ...obj.attrs,
                                    fields: obj.attrs.fields.map((fld) => {
                                        return {
                                            ...fld,
                                            value: val[fld.placeholder],
                                            disabled: this.readOnly,
                                        };
                                    }),
                                    value: obj.attrs.fields.map((fld) => {
                                        return {
                                            ...fld,
                                            value: val[fld.placeholder],
                                        };
                                    }),
                                },
                            });
                        });
                        if (!this.values.length) {
                            let obj = new Object();
                            Object.assign(obj, form);
                            newFormField.push({
                                ...obj,
                                attrs: {
                                    ...obj.attrs,
                                    fields: obj.attrs.fields.map((fld) => {
                                        return {
                                            ...fld,
                                            value: undefined,
                                            disabled: this.readOnly,
                                        };
                                    }),
                                    value: obj.attrs.fields.map((fld) => {
                                        return {
                                            ...fld,
                                            value: undefined,
                                        };
                                    }),
                                },
                            });
                        }

                        return newFormField;
                    } else return mod;
                } else return mod;
            });
            this.models = models;
        },
        findInvalidModel() {
            const requiredRule = (val) => (isPrimitive(val) ? !!val : isNotEmpty(val)) || "required";

            const search = (models) => {
                return models.find((model) => {
                    if (this.isMultipleModel(model)) return search(model);
                    const rules = model.mandatory ? [...model.attrs.rules, requiredRule] : model.attrs.rules;
                    const value = model.attrs.value;
                    const faultRule = rules.find((rule) => rule(value) !== true);

                    return !!faultRule;
                });
            };

            return search(this.models);
        },
        formValidate() {
            let state = true;
            this.$refs["form-components"].forEach((comp) => {
                if (comp.required && comp.value) {
                    state = false;
                }
            });
            return state;
        },
        validate() {
            this.$refs?.["form-components"]?.forEach((component) => {
                if (component.component == "combo") {
                    component?.valueSync?.map((el) => {
                        if (el?.platform != "" && el?.username == "") {
                            el["error"] = true;
                        } else if (el?.platform == "" && el?.username != "") {
                            el["error"] = true;
                        }
                        component.validate();
                    });
                }
            });

            const models = this.models.map((model) => {
                // if (this.isMultipleModel(model)) return { multi: true, label: model[0].attrs.label, value: model.map(({ attrs }) => ({ value: attrs.value,label: attrs.label, placeholder: attrs.placeholder })) };
                // RETURN ARRAY OF VALUES IN CASE OF MULTIPLE TRUE
                if (model.type == "combo") {
                    this.$emit(EventsEnum.VALUE_UPDATE, model);
                    this.$forceUpdate();
                }
            });

            return !this.findInvalidModel();
        },
        isMultipleModel(model) {
            return Array.isArray(model);
        },
        onSubmit() {
            const models = this.models.map((model) => {
                // if (this.isMultipleModel(model)) return { multi: true, label: model[0].attrs.label, value: model.map(({ attrs }) => ({ value: attrs.value,label: attrs.label, placeholder: attrs.placeholder })) };
                if (this.isMultipleModel(model))
                    return [
                        ...model.map(({attrs}) => ({
                            label: attrs.label,
                            value: attrs.value,
                            placeholder: attrs.placeholder ?? "",
                        })),
                    ]; // { label: model[0].attrs.label, value: model.map(({ attrs }) => ({ value: attrs.value })) };

                return {
                    label: model.attrs.label,
                    value: model.attrs.value,
                    placeholder: model.attrs.placeholder ?? "",
                };
            });
            this.$emit(EventsEnum.SUBMIT, models);
        },
    },
    created() {
        this.initComponentsOptionsModels();
    },
};
</script>

<style lang="scss" scoped>
.tw-w-fit {
    width: fit-content;
}

.bg-brand-color {
    background-color: var(--brand-color);
}
</style>
