<template>
    <div class="multi-block tw-rounded-lg tw-p-4 tw-bg-gray-100" :class="{'tw-flex': $attrs.placeholder === 'phone number'}">
        <template v-for="(val, i) in valueSync">
            <DynamicFormElement :key="i" v-model="val.value" v-bind="val" :required="val.mandatory" :disabled="readOnly" :countryCodes="countryCodes" :component="val.type" :readOnly="readOnly" ref="form-components" name="block-field" class="tw-mb-8 tw-mt-4" :class="$attrs.placeholder === 'phone number' ? '' : 'tw-w-full'" />
        </template>
    </div>
</template>

<script>
const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
    FOCUS: "focus",
});

export default {
    model: {props: "value", event: EventsEnum.VALUE_UPDATE},
    components: {
        DynamicFormElement: () => import("./../../DynamicFormElement"),
    },
    data() {
        return {
            isChanged: false,
        };
    },
    props: {
        value: Array,
        readOnly: {
            type: Boolean,
            default: false,
        },
        countryCodes: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        valueSync: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
    },
    methods: {},
};
</script>

<style lang="scss" scoped></style>
