<template>
    <NeoInput v-bind="$attrs" v-on="$listeners" :bg="'#fff'" :borderFull="'1px solid #dedede'" :value="valueSync" @input="onInput" role="textbox" :placeholder="getPlaceholder" />
    <!-- <input  v-bind="$attrs" v-on="$listeners" :value="valueSync" @input="onInput" role="input" /> -->
</template>

<script>
import NeoInput from "@/components/input";

const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
    FOCUS: "focus",
});

export default {
    model: {props: "value", event: EventsEnum.VALUE_UPDATE},
    components: {
        NeoInput,
    },
    props: {
        value: null,
        label: {
            type: String,
            default: "",
        },
    },
    computed: {
        valueSync: {
            get() {
                if (this.$attrs.placeholder === "data retention period" && !this.value) {
                    this.valueSync = 180;
                }
                return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
        getPlaceholder() {
            return this.$attrs.help_text && ["twitter_username", "facebook_username", "linkedin_username", "instagram_username"].includes(this.$attrs.placeholder) ? this.$attrs.label.split("_").join(" ") : this.label.split("_").join(" ");
        },
    },
    methods: {
        onInput(val) {
            this.valueSync = val;
            if (this.$attrs.placeholder === "data retention period") {
                if (val < 30) this.valueSync = 30;
                else this.valueSync = val;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
[role="textbox"] {
    width: 100%;
    font-size: 16px !important;
    border-bottom: 1px solid #dedede;
    &.invalid-field-border {
        border: 1px solid;
        top: 1px;
        border: 1px solid #f87171 !important;
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
}
</style>
