<template>
    <div class="tw-flex tw-wrap tw-w-fit radio-com">
        <div v-for="(option, idx) in options" :key="option" class="tw-space-x-2 radio-button tw-cursor-pointer tw-flex tw-items-center tw-mr-4">
            <input v-model="valueSync" v-bind="$attrs" v-on="$listeners" :value="option" type="radio" :id="'radio' + option + componentId + idx" :style="{'--size': size}" />
            <label v-if="option" :for="'radio' + option + componentId + idx" v-text="option" />
        </div>
    </div>
</template>

<script>
const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
});

export default {
    inheritAttrs: false,
    model: {prop: "value", event: EventsEnum.VALUE_UPDATE},
    props: {
        value: String,
        options: {
            type: Array,
            required: true,
        },
        size: String,
    },
    computed: {
        valueSync: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
        componentId() {
            const max = 100;
            return Math.floor(Math.random() * max);
        },
    },
};
</script>

<style lang="scss" scoped>
.tw-w-fit {
    width: fit-content;
}
[type="radio"] {
    --size: 1rem;
    height: var(--size);
    width: var(--size);
    color: var(--brand-color);
    border-color: #d1d5db;

    &:checked {
        -webkit-appearance: none;
        border-radius: 100%;
        height: var(--size);
        width: var(--size);
        background-color: currentColor;
        position: relative;

        &::after {
            display: flex;
            justify-content: center;
            align-items: center;
            content: "●";
            top: -0.04em;
            color: #fff;
            position: absolute;
            height: var(--size);
            width: var(--size);
            font-size: var(--size);
        }
    }
}
.radio-com.invalid-field-border {
    border-bottom: 0 solid #f87171 !important;
    .radio-button {
        label {
            color: #f87171;
        }
    }
}
</style>
