<template>
    <div class="tw-flex tw-wrap tw-w-fit">
        <div v-for="(option, idx) in options" :key="option" class="tw-space-x-2 tw-cursor-pointer tw-flex tw-items-center">
            <input v-model="valueSync" v-bind="$attrs" v-on="$listeners" :value="option" type="checkbox" :style="{'--size': size}" :id="option + idx" />
            <label v-if="option" :for="option + idx" v-text="option" />
        </div>
    </div>
</template>

<script>
const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
});

export default {
    inheritAttrs: false,
    model: {prop: "value", event: EventsEnum.VALUE_UPDATE},
    props: {
        value: {
            type: Array,
            default: () => [] || "",
        },
        options: {
            type: Array,
            required: true,
        },
        size: String,
    },
    computed: {
        valueSync: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.tw-w-fit {
    width: fit-content;
}

[type="checkbox"] {
    --size: 1rem;
    height: var(--size);
    width: var(--size);
    color: var(--brand-color);
    border-color: #d1d5db;
    border-radius: 0.25rem;

    &:checked {
        -webkit-appearance: none;
        height: var(--size);
        width: var(--size);
        background-color: currentColor;
        position: relative;

        &::after {
            content: "✔";
            top: -0.1em;
            left: 0.13em;
            color: #fff;
            position: absolute;
            height: var(--size);
            width: var(--size);
            font-size: var(--size);
        }
    }
}
</style>
