import { render, staticRenderFns } from "./MultiBlock.vue?vue&type=template&id=305917aa&scoped=true&"
import script from "./MultiBlock.vue?vue&type=script&lang=js&"
export * from "./MultiBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305917aa",
  null
  
)

export default component.exports