<template>
    <div>
        <VueMultiselect v-if="$attrs && $attrs.placeholder === 'country_code'" :show-labels="false" group-values="categories" label="value" group-label="group" :group-select="false" :searchable="true" :allow-empty="true" placeholder="Select Country" track-by="value" :options="getCountryCodes" role="combobox1" v-bind="$attrs" v-on="$listeners" @focus.native="$emit('focus', $event)" v-model="valueSync"></VueMultiselect>
        <VueMultiselect v-else v-model="valueSync" v-bind="$attrs" v-on="$listeners" @focus.native="$emit('focus', $event)" role="combobox" :placeholder="$attrs.placeholder" />
    </div>
</template>

<script>
import VueMultiselect from "@/components/vue-multiselect";
import OsintAxios from "@/axios/osint.js";

const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
});
export default {
    components: {
        VueMultiselect,
    },
    props: {
        value: null,
        label: {
            type: String,
            default: "",
        },
        countryCodes: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        valueSync: {
            get() {
                if (!this.value?.res?.country_with_code && this.value?.includes("+")) {
                    let value = this.countryCodes.filter((el) => el.res.country_phone_code === this.value?.split("+")[1]);
                    return value;
                } else return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
        getCountryCodes() {
            return [
                {
                    group: "Country Codes",
                    categories: this.countryCodes,
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
[role="combobox"] {
    width: 70%;
    position: relative;
    &.invalid-field-border {
        top: 1px;
        border: 1px solid #f87171 !important;
        border-radius: 0.315rem !important;
        border-bottom-right-radius: 0.315rem !important;
    }
}
::v-deep {
    .multiselect__tags {
        min-height: unset;
    }
    .multiselect__option {
        min-height: unset;
    }

    .multiselect:not(.basic-view) .multiselect__tags {
        height: 2.5rem !important;
    }
}
</style>
