<template>
    <NeoDatePicker :editable="false" format="DD-MMM-YYYY" class="tw-w-80 tw-h-10 tw-rounded" v-model="valueSync" v-bind="$attrs" :placeholder="getPlaceholder" v-on="$listeners" @click.native="$emit('focus', $event)" role="textbox" bg="#fff" />
</template>

<script>
// import NeoDatePicker from '@/components/datepicker';
import NeoDatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
});

export default {
    components: {
        NeoDatePicker,
    },
    props: {
        value: null,
    },
    computed: {
        valueSync: {
            get() {
                return this.value ? new Date(this.value) : "";
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },

        getPlaceholder() {
            return this.$attrs?.placeholder.split("_").join(" ");
        },
    },
};
</script>

<style lang="scss" scoped>
// [role="textbox"] {
// 	width: 50%;
// 	font-size: 16px;
//     border-bottom: 1px solid #dedede;
// }
</style>
