<template>
    <div class="">
        <VueFilePond v-bind="$attrs" @click.native="$emit('focus', $event)" name="file" ref="pond" label-idle="Drag and drop or click here to upload a screenshot, Document or PDF..." credits="false" @processfile="processFile" @removefile="removeFile" :files="myFiles" :server="imageUploadServer" v-bind:allow-multiple="false" accepted-file-types="image/jpeg, image/png, application/pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document " />
    </div>
</template>

<script>
import VueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import VueCookies from "vue-cookies";
import rawAxios from "axios";
const EventsEnum = Object.freeze({
    VALUE_UPDATE: "value:update",
});

export default {
    components: {
        VueFilePond: VueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginPdfPreview),
    },
    props: {
        value: null,
    },
    data() {
        return {
            imageUploadServer: {
                process: {
                    url: `${process.env.VUE_APP_BLACK_BOX_URL}/api/v1/uploads/reports/images`,
                    method: "POST",
                    headers: {
                        Authorization: "",
                    },
                },
                load: this.loadScreenshot,
            },
            openImageURL: "",
            myFiles: [],
            screenshot: {},
        };
    },
    computed: {
        valueSync: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
            },
        },
    },
    methods: {
        processFile(error, file) {
            if (!error) {
                this.screenshot.available = true;
                this.screenshot["location"] = JSON.parse(file.serverId).file;
                this.screenshot["file_name"] = file.filename;
                this.screenshot["file_mime_type"] = file.fileType;
                this.screenshot["size"] = file.fileSize;
                this.valueSync = this.screenshot;
                this.$set(this.valueSync, this.screenshot);
            }
        },
        removeFile() {
            this.screenshot = {
                available: false,
            };

            this.valueSync = this.screenshot;
            this.$set(this.valueSync, this.screenshot);
        },
        async loadScreenshot(source, load) {
            try {
                this.openImageURL = `https://neotas-reports-storage.s3.eu-west-2.amazonaws.com/reports-templates/${source}`;
                let response = await rawAxios.get(this.openImageURL, {responseType: "blob"});
                load(response.data);
            } catch (error) {
                this.openImageURL = "";
                load();
            }
        },
        initFiles() {
            console.log("this.$refs :>> ", this.$refs);
        },
    },
    mounted() {
        const token = VueCookies.get("token");
        this.imageUploadServer.process.headers["Authorization"] = `Bearer ${token}`;
        if (this.value && this.value.available) {
            this.screenshot = this.value;
            this.myFiles = [{source: this.screenshot.location, options: {type: "local"}}];
        }
        //  ? (this.screenshot = this.value) : (this.screenshot = { available: false });
    },
};
</script>
